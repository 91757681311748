export default function (replaceUrl: boolean) {
  if (import.meta.server) return
  const route = useRoute()
  const user = useUserStore()
  const query = { ...route.query }

  // Save UTM in URL in user
  if (query.utm_source) {
    console.log('Saved UTMs.')
    user.utm_source = query.utm_source as string
    user.utm_medium = query.utm_medium as string
    user.utm_campaign = query.utm_campaign as string
    user.utm_content = query.utm_content as string
    user.utm_term = query.utm_term as string
  }

  // Check if entry UTM
  if (!user.utm_entry && user.utm_source) user.utm_entry = user.currentUTM()

  onMounted(() => {
    if (replaceUrl) {
      // Remove UTM from URL
      delete query.utm_source
      delete query.utm_medium
      delete query.utm_campaign
      delete query.utm_content
      delete query.utm_term

      navigateTo({ path: route.path, query: query }, {
        replace: true,
      })
    }
  })
}
